import { useCallback, useEffect, useState } from 'react'

const useIsLandscape = () => {
  const [isLandscape, setIsLandscape] = useState<boolean>(false)

  const handleWindowSizeChange = useCallback(() => {
    setIsLandscape(window.innerWidth > window.innerHeight)
  }, [])

  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [handleWindowSizeChange])

  return isLandscape
}

export default useIsLandscape
