import { Text, View, Spacer } from '@cnd/elements'
import Image from 'next/image'

const Copywrite = ({ mini = false }) => {
  return (
    <View center style={{ marginTop: 25, marginBottom: 30 }}>
      <View center row>
        <Image
          src={`/images/bountiful.webp`}
          height={(mini ? 0.06 : 0.12) * 440}
          width={(mini ? 0.06 : 0.12) * 2092}
          alt={'Bountiful Name'}
        />
      </View>
      <View>
        <Spacer height={45} />
        <Text size={13} color={`white`} center style={{ maxWidth: 600 }}>
          The Bountiful Group LTD is a Limited Company registered in England, UK. The Bountiful Group LTD Also
          trades publicly as Bountiful, My Hiro and Crispy Rolls.
        </Text>
        <Spacer height={25} />
        <Text size={13} color={`white`} center>
          Bountiful ® is a registered trade mark used by The Bountiful Group LTD.
        </Text>
        <Spacer height={10} />
        <Text size={13} color={`white`} center>
          My Hiro ™ is a trade mark used by The Bountiful Group LTD.
        </Text>
        <Spacer height={20} />
        <Text size={10} color={`white`} center style={{ maxWidth: 600 }}>
          No part of this material may be reproduced in any form, or referred to in any other publication,
          without written permission.
        </Text>
        <Spacer height={20} />
      </View>
    </View>
  )
}

export default Copywrite
