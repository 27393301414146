import { Text, View, Button } from '@cnd/elements'
import useIsLandscape from 'hooks/useIsLandscape'
import Image from 'next/image'
import { useRouter } from 'next/navigation'

const Header = ({
  actionButtonClick = null,
  altButtonOnClick,
  actionButtonText = '',
  altButtonText = '',
  invert = false,
}) => {
  const router = useRouter()
  const isLandscape = useIsLandscape()
  return (
    <View
      row
      spaceBetween
      center
      style={{
        paddingInline: 50,
        paddingBlock: 45,
        top: 0,
        left: 0,
        right: 0,
      }}
    >
      <View row style={{ alignItems: 'center' }} onClick={() => router.push('/')}>
        <Image
          src={`/images/logo.${!invert ? `webp` : `png`}`}
          height={isLandscape ? 0.8 * 99 : 99}
          width={isLandscape ? 0.8 * 70 : 70}
          alt={'Bountiful Logo'}
        />
        {!invert && isLandscape && (
          <Image
            src={`/images/bountiful.webp`}
            height={0.16 * 440}
            width={0.16 * 2092}
            alt={'Bountiful Name'}
            style={{ marginLeft: 20 }}
          />
        )}
      </View>
      <View row>
        <View
          center
          motion
          whileTap={{ scale: 0.8 }}
          style={{ borderRadius: 0, paddingBlock: 15, width: 100 }}
          onClick={() => router.push(`/why-bountiful`)}
        >
          <Text size={18} block={false} weight={400} color={invert ? 'black' : 'white'}>
            Team
          </Text>
        </View>

        {isLandscape && !!altButtonText && (
          <View
            style={{ borderRadius: 0, paddingBlock: 15, width: 100 }}
            onClick={altButtonOnClick}
            row
            spaceBetween
            center
          >
            <Text size={18} block={false} weight={400} color={invert ? 'black' : 'white'}>
              {altButtonText}
            </Text>
          </View>
        )}
        {!!actionButtonText && (
          <View
            motion
            whileTap={{ scale: 0.8 }}
            center
            style={{
              height: 70,
              paddingBlock: 10,
              width: 130,
              background: 'white',
              borderRadius: 100,
              marginLeft: 60,
            }}
            onClick={actionButtonClick}
          >
            <Text weight={700} size={18}>
              {actionButtonText}
            </Text>
          </View>
        )}
      </View>
    </View>
  )
}

export default Header
