import { Text, AnimatePresence, View } from '@cnd/elements'
import { addIndex, map, split } from 'ramda'
import React, { useState, useEffect, useRef } from 'react'
import { useIsomorphicLayoutEffect } from 'usehooks-ts'

export const useTimeout = (callback: () => void, delay: number | null): void => {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the timeout.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return
    }

    const id = setTimeout(() => {
      savedCallback.current()
    }, delay)

    return () => {
      clearTimeout(id)
    }
  }, [delay])
}

const TypeWriter = ({ words, style = {}, letterStyle = {}, renderLetter = null, containerStyle = {} }) => {
  const [text, setText] = useState('')
  const [loopNum, setLoopNum] = useState(0)
  const [period, setPeriod] = useState(2000)
  const [isDeleting, setIsDeleting] = useState(false)
  const [delta, setDelta] = useState(500)

  const tick = () => {
    const i = loopNum % words.length
    const fullTxt = words[i]

    let newText = ''
    if (isDeleting) {
      newText = fullTxt.substring(0, text.length - 1)
    } else {
      newText = fullTxt.substring(0, text.length + 1)
    }

    let delta = 200 - Math.random() * 100

    if (isDeleting) {
      setDelta(delta / 2)
    }

    if (!isDeleting && newText === fullTxt) {
      setDelta(period)
      setIsDeleting(true)
    } else if (isDeleting && newText === '') {
      setIsDeleting(false)
      setLoopNum(loopNum + 1)
      setDelta(500)
    }

    setText(newText)
  }

  useEffect(() => {
    const timeout = setTimeout(tick, delta)
    return () => {
      clearTimeout(timeout)
    }
  }, [text, delta])

  return (
    <AnimatePresence>
      <View row style={containerStyle}>
        {addIndex(map)(
          (letter: string, i) => (
            <View
              row
              key={i}
              motion
              initial={{ opacity: 0.5, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              style={{ marginRight: 0, ...letterStyle }}
              transition={{ type: 'spring' }}
            >
              {!!renderLetter && renderLetter(letter)}
              {!renderLetter && <Text style={style}>{letter}</Text>}
            </View>
          ),
          split('', text)
        )}
      </View>
    </AnimatePresence>
  )
}

export default TypeWriter
